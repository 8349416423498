// This pack's primary purpose is to import application.scss.

// JS Rails routes
import * as Routes from 'routes.js.erb'
window.Routes = Routes

import 'site-watch.scss'
import 'utils/countdown'
import 'utils/banners'
import 'bootstrap/js/src/modal'
